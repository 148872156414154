// JsFromRoutes CacheKey 1dfd352c481af7a1c2a0f5ebf13b614d
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@/util/requests'

export default {
  create: /* #__PURE__ */ definePathHelper('post', '/api/track_events'),
  createFixdCare: /* #__PURE__ */ definePathHelper(
    'post',
    '/api/track_fixd_care',
  ),
}
